'use client'

import { i18n, Locale } from '@/i18n'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import { Fragment } from 'react'
import { cn } from '@/lib/cn'

export default function LanguagePicker() {
  const pathname = usePathname()
  const [currentLocale, setCurrentLocale] = useState<Locale | null>(null)

  useEffect(() => {
    if (!pathname) return setCurrentLocale(null)
    const slugsArray = pathname.split('/')
    const firstSlug = slugsArray[1]
    const currentLocale = i18n.locales.find((locale) => locale === firstSlug) || i18n.defaultLocale
    setCurrentLocale(currentLocale || i18n.defaultLocale)
  }, [pathname])

  return (
    <div>
      {i18n?.locales?.map((locale, index) => {
        return (
          <Fragment key={locale}>
            {index > 0 && (
              <span className='mx-2' aria-hidden>
                |
              </span>
            )}
            <Link
              href={locale === i18n.defaultLocale ? '/' : `/${locale}`}
              prefetch={false}
              className={cn('text-sm', {
                underline: locale === currentLocale,
              })}
              onClick={() => {
                document.cookie = `locale=${locale}; path=/;`
              }}
            >
              {locale?.toLocaleUpperCase()}
            </Link>
          </Fragment>
        )
      })}
    </div>
  )
}
