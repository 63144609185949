'use client'

import { cn } from '@/lib/cn'
import { useEffect, useRef } from 'react'

export default function CursorShadow() {
  const shadowRef = useRef<HTMLDivElement>(null)
  const dotRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (window.innerWidth < 768) return
    const shadow = shadowRef.current
    const dot = dotRef.current
    if (!shadow || !dot) return

    let mouseX = 0,
      mouseY = 0,
      dotX = 0,
      dotY = 0,
      shadowX = 0,
      shadowY = 0,
      isFirstEvent: boolean | null = null

    // Update mouse position on mousemove
    const mouseMoveHandler = (event: MouseEvent) => {
      // Set isFirstEvent
      if (isFirstEvent === null) isFirstEvent = true
      else if (isFirstEvent === true) isFirstEvent = false

      mouseX = event.clientX
      mouseY = event.clientY

      shadow.style.opacity = '1'
      dot.style.opacity = '1'
    }

    document.addEventListener('mousemove', mouseMoveHandler)

    // Shadow animation logic
    const updateShadow = () => {
      dotX += (mouseX - dotX) * (isFirstEvent ? 1 : 0.2)
      dotY += (mouseY - dotY) * (isFirstEvent ? 1 : 0.2)
      shadowX += (mouseX - shadowX) * (isFirstEvent ? 1 : 0.1)
      shadowY += (mouseY - shadowY) * (isFirstEvent ? 1 : 0.1)

      dot.style.left = `${dotX - dot.offsetWidth / 2}px`
      dot.style.top = `${dotY - dot.offsetHeight / 2}px`
      shadow.style.left = `${shadowX - shadow.offsetWidth / 2}px`
      shadow.style.top = `${shadowY - shadow.offsetHeight / 2}px`

      requestAnimationFrame(updateShadow)
    }

    // Start animation
    updateShadow()

    // Cleanup on component unmount
    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler)
    }
  }, [])

  return (
    <>
      <div
        ref={shadowRef}
        className={cn(
          'theme-brand-2 fixed border-[1.5px] border-surface-solid rounded-full pointer-events-none -z-10 size-11 opacity-0 transition-opacity duration-300',
        )}
      />
      <div
        ref={dotRef}
        className='theme-brand-2 fixed bg-surface-solid rounded-full pointer-events-none z-[200] size-1.5 opacity-0 transition-opacity duration-300'
      />
    </>
  )
}
