import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.7_next@15.1.5_react@19.0.0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.7_next@15.1.5_react@19.0.0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.7_next@15.1.5_react@19.0.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.9_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.9_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.9_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.9_react-dom@19.0.0_react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"style\":[\"normal\",\"italic\"],\"display\":\"swap\",\"variable\":\"--font-dm-sans\",\"adjustFontFallback\":false}],\"variableName\":\"dmSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/appInsights/AppInsightsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/BaseImage/BaseImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/CursorShadow/CursorShadow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/VimeoPlayer/VimeoPlayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/renderings/Footer/LanguagePicker.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
