'use client'

import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights: ApplicationInsights | null = null

try {
  if (
    process.env.NEXT_PUBLIC_APPINSIGHTS_INSTRUMENTATIONKEY &&
    process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTIONSTRING
  ) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.NEXT_PUBLIC_APPINSIGHTS_INSTRUMENTATIONKEY,
        connectionString: process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTIONSTRING,
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        disablePageUnloadEvents: ['beforeunload', 'unload'],
        disableCookiesUsage: true,
      },
    })
    appInsights.loadAppInsights()
  }
} catch {
  // Do nothing
}

export default function AppInsightsProvider() {
  const pathname = usePathname()

  useEffect(() => {
    if (appInsights) {
      // Get search params from window.location
      const searchParams = window.location.search.slice(1)
      const url = pathname + (searchParams ? `?${searchParams}` : '')
      appInsights.trackPageView({
        name: url,
        properties: {
          'app name': 'Website',
        },
      })
    }
  }, [pathname])

  return null
}
